import React from "react";
import './multi-step-questions.scss';
import QuestionImage from "../../../../common/cw-question-image/cw-question-image";
import QuestionTitle from "../../../../common/question/question-title/question-title";
import { getImageSource } from "../../../../../utility/image-source";
import { ImageConstants } from "../../../../../constants/constants";
import AssessmentSolution from "../../../../common/assessment-solution/assessment-solution";
import CommonUtils from "../../../../../utility/common-utilities";
import SharedThemeCategory from "../../../../../../shared-storage/category-theme";
import Question from "../../question";

export default class MultiStep extends React.Component {

    state = {
        currentIndex: 0
    }

    constructor(props) {
        super(props);
        let content = JSON.stringify(this.props.content)
        this.state.content = JSON.parse(content);
    }

    componentDidMount() {

    }

    getQuesComponent(index) {

        let content = this.state.content.content.steps[index];
        content._id = this.state.content._id;
        let answer = this.props.answer;

        let isCorrect = answer && answer.steps[index] && answer.steps[index].status == 'CORRECT' ? true : false

        return (
            <div className="question-container">

                <div className="part-wrapper row mb-3">
                    <div className="col d-flex align-items-center">
                        <span className="multi-step-question-part-text-1">{`${this.props.questionNumber}${CommonUtils.getAlphabetForIndex(index)}`}</span>
                        {isCorrect ? <img className="tick-icon-in-multi-step ml-2" src={getImageSource(ImageConstants.CORRECT_ANSWER_ICON)} /> : <img className="tick-icon-in-multi-step ml-2" src={getImageSource(ImageConstants.INCORRECT_ANSWER_ICON)} />}
                    </div>
                </div>

                <Question content={content}
                    mulipStepQuestion={this.state.content}
                    isMultiStep={true}
                    answer={answer && answer.steps && answer.steps.length ? answer.steps[index] : null}
                    categoryThemeId={this.props.categoryThemeId}
                    status={this.props.status}
                    origin={this.props.origin} />

                {this.state.content && this.state.content.content && this.state.content.content.steps && this.state.content.content.steps.length - 1 > index ?
                    <div className="d-flex justify-content-center">
                        <div className="step-separator" style={{ backgroundColor: `${this.theme[`themeColor`]}` }} ></div>
                    </div>
                    :
                    <></>
                }
            </div>
        )
    }

    renderMultiStepQuestionInfo = (question, answer) => {

        // console.log(this.props.questionNumber)

        if (question.content.isMultiStep) {

            let elements = []
            let isOverallWrong = false

            for (let i = 0; i < question.content.steps.length; i++) {

                let isCorrect = answer && answer.steps[i] && answer.steps[i].status == 'CORRECT' ? true : false

                if (!isCorrect)
                    isOverallWrong = true

                elements.push(<div key={question._id + i} >
                    <div className="part-wrapper row mb-3">
                        <div className="col-1">
                            <span className="multi-step-question-part-text">{`${this.props.questionNumber}${CommonUtils.getAlphabetForIndex(i)}`}</span>
                        </div>
                        <div className="col-1">
                            {isCorrect ? <img className="tick-icon-in-multi-step " src={getImageSource(ImageConstants.CORRECT_ANSWER_ICON)} /> : <img className="tick-icon-in-multi-step" src={getImageSource(ImageConstants.INCORRECT_ANSWER_ICON)} />}
                        </div>
                    </div>
                </div>
                )
            }

            return (
                <div className={`multi-step-question-info-container ${isOverallWrong ? "" : 'correct-bg-color'}`}>
                    <div className="multi-step-question-info-title mb-2">This question has got {question.content.steps.length} Parts:</div>
                    {elements}

                    {isOverallWrong ?
                        <div className="all-questions-wrong">
                            <div className="danger-text">Overall Incorrect <img className="tick-icon-in-multi-step ml-3" src={getImageSource(ImageConstants.INCORRECT_ANSWER_ICON)} /></div>
                        </div> :
                        <div className="correct-text">Overall Correct <img className="tick-icon-in-multi-step ml-3" src={getImageSource(ImageConstants.CORRECT_ANSWER_ICON)} /> </div>}
                </div>
            )
        } else return <></>
    }


    // _onMomentumScrollEnd = ({ nativeEvent }) => {
    //     const position = nativeEvent.contentOffset;
    //     // const index = Math.round(nativeEvent.contentOffset.x / PAGE_WIDTH);

    //     if (index !== this.state.currentIndex) {
    //         //console.log(index)
    //         this.setState({ currentIndex: index })
    //     }
    // }

    render() {

        // setCategoryThemeStyles(this.props.categoryThemeId)
        this.theme = SharedThemeCategory.getThemeCategory(this.props.categoryThemeId)

        let content = this.state.content;

        return (
            <div className="view-answer-multi-step-questions">

                <QuestionTitle title={content.content.title} hideBackground={true} queNo={this.props.quesNo ? this.props.quesNo : null} categoryThemeId={this.props.categoryThemeId} />

                <QuestionImage images={content.content.images} categoryThemeId={this.props.categoryThemeId} />

                {this.renderMultiStepQuestionInfo(content, this.props.answer)}

                {/* <AssessmentSolution content={this.state.content} categoryThemeId={this.props.categoryThemeId}></AssessmentSolution> */}

                <div className="d-flex justify-content-center">
                    <div className="step-separator" style={{ backgroundColor: `${this.theme[`themeColor`]}` }} ></div>
                </div>




                {/* <View style={Styles.paginationContainer}>
                    {
                        this.state.content.content && this.state.content.content.steps ?
                            this.state.content.content.steps.map((stepContent, i) =>
                                (
                                    <View style={Styles.page}>

                                        <Text style={Styles.pageText}>{i + 1}</Text>
                                        {
                                            this.state.currentIndex == i ?
                                                <View style={Styles.activePage}></View> :
                                                <View style={Styles.inActivePage}></View>
                                        }
                                    </View>
                                )) :
                            <></>
                    }

                </View> */}

                <div className="step-questions-container">

                    <div>
                        {
                            this.state.content.content && this.state.content.content.steps ?

                                this.state.content.content.steps.map((stepContent, i) => this.getQuesComponent(i))
                                :
                                <></>

                        }
                    </div>
                </div>

                <AssessmentSolution content={this.state.content} categoryThemeId={this.props.categoryThemeId}></AssessmentSolution>

            </div>


        );
    }
}