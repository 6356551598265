import React from "react";
import './home-component.scss';
import SharedProfile from "../../../shared-storage/profile";
import { getImageSource } from "../../utility/image-source";
import { Constants, ImageConstants } from "../../constants/constants";
import HomeworkApi from "../../apis/homework";
import Path from "../routes/routes-path";
import EventApi from "../../apis/event";
import CommonApi from "../../apis/common";
import LayoutShared from "../common/layout-shared/layout-shared";
import MyAssessmentResult from "../my-assessment-result/my-assessment-result";
import DiagnosticAssessmentReport from "../events/assessment-report/assessment-report";
import ChapterList from "./chapter-list/chapter-list";
import StartAssessment from "../common/start-assessment/start-assessment";
import CommonChapterList from "../common/chapter-list/chapter-list";
import ChapterLockModal from "../chapter-list-v1/chapter-lock-modal/chapter-lock-modal";
import CwSubscriptionModal from "../common/cw-subscription-modal/cw-confirmation-modal";
import ChapterActivityList from "./chapter-activity-list/chapter-activity-list";

class HomeComponent extends React.Component {


    constructor(props) {
        super(props)

        this.getUserHomeworkActivities({}, null, null, true)

        this.state.showBlur = this.meta && this.meta.showBlur ? this.meta.showBlur : false;

        this.getDynamicContent()
        this.getDiagnosticAssessmentStatus()

        this.layoutShared = LayoutShared.getLayout()
    }

    state = {
        userInfo: {},
        passedDueDateHomeworkCount: 0,
        activeHomeworkCount: 0,
        passedDueDateClassworkCount: 0,
        activeClassworkCount: 0,
        events: [],
        showRefresherCourseCard: false,
        showSubjectSelection: false,
        subjectsList: []
    }

    componentDidMount() {

        let userInfo = SharedProfile.getProfile()
        this.setState({ userInfo })

        // console.log(userInfo)

        // this.getEvents()

        // this.layoutShared.toggleSidebar(false, this.props.history.location.pathname)

        // if (Constants.CLASS_SPECIFIC_CONFIGURATION.includes(userInfo.class) && !userInfo.subjectId) {
        //     this.getSubjectList()
        // }

        // console.log(this.props.history.location.state)

        // if (this.props.history.location && this.props.history.location.state && this.props.history.location.state.showSubjectSelection) {
        //     this.getSubjectList()
        // }

        // this.resetZoom()

        this.onSelectSubject({
            "_id": "66a9d13f933e00065a431f07",
            "title": "MATHS",
        }, 0)

    }


    resetZoom() {
        if (window.electron) {
            window.electron.ipcRenderer.send('reset-zoom', ['ping'])
        }

    }


    getSubjectList() {
        let payload = {}
        CommonApi.getSubjectsList(payload).then(res => {
            console.log(res)
            this.setState({ showSubjectSelection: true, subjectsList: res.subjectList })

        }).catch(err => {
            console.log(err)
        })
    }

    getDiagnosticAssessmentStatus = () => {

        let payload = {}

        CommonApi.getDiagnosticAssessmentStatus(payload).then((res) => {
            console.log(res)
            this.setState({ diagnosticAssessmentRes: res, showRefresherCourseCard: res.showRefresherCourseCard })
        }).catch(err => {
            if (err) {
                this.setState({ showRefresherCourseCard: false })
                return;
            }
        })
    }



    getDynamicContent = () => {

        let payload = {
            configurationType: 'NEW_LAUNCH',
            status: 'ACTIVE'
        }

        CommonApi.getConfigurationsList(payload).then((data) => {
            //console.log(data.response[0])
            if (data) {
                if (data.response.length) {
                    this.setState({ dynamicContent: data.response[0], showDynamicContent: true })
                }
            }
        }).catch(err => {
            if (err) {
                //console.log(err)
                return
            }
        })
    }

    navigateToLaunchedContent = () => {

        let content = this.state.dynamicContent

        if (content.type == 'COURSE') {

            // this.navigateToCourse(content)
            // return

        } else if (content.type == 'EVENT') {

            // if (content.eventType == 'TWO_STEP_EVENT') {

            //     this.navigateToMonthlyMathsMarathon({ eventId: content.id })

            // } else if (content.eventType == 'MPL') {

            //     if (this.state.mplEvent && this.state.mplEventRes) {
            //         this.props.navigation.navigate("MPLScholarshipScreen", { meta: { event: this.state.mplEvent, eventRes: this.state.mplEventRes } })
            //     }

            // } else if (content.eventType == 'THREE_STEP_EVENT') {

            // }

        } else if (content.type == 'EVENT_CAMPAIGN') {
            // if (content.eventType == 'MPL') {
            //     this.navigateToMplCampaign(content)
            // } else if (content.eventType == 'TWO_STEP_EVENT') {
            //     this.navigateToEventCampaign(content);
            // }
        } else if (content.type == 'HYPER_LINK') {

            if (content.link) {
                window.open(content.link)
            }
        } else if (content.type == "EVENT_LIST") {
            // this.props.navigation.navigate("EventListScreen", { meta: {} })
        }

    }

    getEvents = () => {
        let payload = {}
        this.setState({ loading: true })
        EventApi.getMultipleTwoStepEventStatus(payload).then((events) => {

            //console.log(events)
            this.setState({ events, loading: false })
        }).catch(err => {
            if (err) {
                this.setState({ loading: false })
                return;
            }
        })
    }


    getUserHomeworkActivities = (payload, isFilter, append, getFilter) => {

        payload = payload ? payload : {}

        // payload.type = ["ADAPTIVE_CLASSWORK", "STANDARD_CLASSWORK", "CLASSWORK_ASSESSMENT"]

        HomeworkApi.getUserHomeworkActivities(payload).then((res) => {

            console.log(res)

            if (this.state.loading)
                this.toggleLoading(false)

            if (this.state.fullScreenLoading)
                this.toggleFullScreenLoading(false)



            this.totalPages = res.totalPages;
            if (getFilter) {
                this.getFilters((err, filterResponse) => {

                    if (err)
                        return;

                    this.filters = filterResponse;
                })
            }

            if (append) {

                let content = this.state.content;
                content = content.concat(res.contents)
                this.setState({ content })
            } else {

                if (res.contents.length > 0) {


                    let activeHomeworkCount = 0, passedDueDateHomeworkCount = 0, activeClassworkCount = 0, passedDueDateClassworkCount = 0


                    res.contents.forEach(item => {

                        if (item.type.includes('CLASSWORK')) {
                            if ((item.status === 'ASSIGNED' || item.status === "IN_PROGRESS") && item.homeworkStatus === "ACTIVE") {
                                activeClassworkCount += 1
                            } else if ((item.status === 'ASSIGNED' || item.status === "IN_PROGRESS") && item.homeworkStatus === "PAST_DUE_DATE") {
                                passedDueDateClassworkCount += 1
                            }
                        } else if (item.type === "STANDARD" || item.type === "ADAPTIVE" || item.type === "ASSESSMENT") {
                            if ((item.status === 'ASSIGNED' || item.status === "IN_PROGRESS") && item.homeworkStatus === "ACTIVE") {
                                activeHomeworkCount += 1
                            } else if ((item.status === 'ASSIGNED' || item.status === "IN_PROGRESS") && item.homeworkStatus === "PAST_DUE_DATE") {
                                passedDueDateHomeworkCount += 1
                            }
                        }

                    })


                    this.setState({
                        content: res.contents, activeClassworkCount: activeClassworkCount + activeHomeworkCount,
                        passedDueDateClassworkCount: passedDueDateClassworkCount + passedDueDateHomeworkCount
                    })
                } else {
                    this.setState({ content: res.contents, showSelectChapterModal: true })
                }

            }

            if (isFilter) {
                this.filters = res;
            }
        }).catch(err => {
            if (err) {
                return;
            }
        })

    }


    getFilters(callback) {

        let payload = {}

        HomeworkApi.getHomeworkFilters(payload).then((res) => {
            callback(null, res)
        }).catch(err => {
            callback(err, null)
        })

    }


    toggleLoading = (value, callback) => {
        this.setState({ loading: value }, () => {
            callback && callback()
        });
    }

    toggleFullScreenLoading = (value, callback) => {
        this.setState({ fullScreenLoading: value }, () => {
            callback && callback()
        })
    }

    goToClassworkList() {
        // this.layoutShared.toggleSidebar(true)
        this.props.history.replace({ pathname: Path.MY_CLASSWORK })
    }

    goToHomeworkList() {
        // this.layoutShared.toggleSidebar(true)
        this.props.history.replace({ pathname: Path.MY_HOMEWORK })
    }

    goToEventList() {
        // this.layoutShared.toggleSidebar(true)
        this.props.history.replace({ pathname: Path.EVENT_LIST })
    }

    openSubscriptionPage() {
        window.open(`https://countingwell.com/login?plan=650adb104936dc2d62c00c39&m=${this.state.userInfo.mobileNumber}&cc=${this.state.userInfo.countryCode}`)
    }

    onPressDiagnosticAssessment = (action) => {

        if (action === 'VIEW_ANSWERS') {

            let meta = {
                chapterTitle: "Diagnostic Assessment for Bridge Course",
                assessmentActivityId: this.state.diagnosticAssessmentRes.assessmentActivityId,
                categoryThemeId: "1",
                assessmentId: this.state.diagnosticAssessmentRes.assessmentId,
                from: Constants.REFRESHER_COURSE
            }

            this.setState({ showViewAnswersScreen: true, assessmentEndScreenInfo: meta })


        } else if (action === 'VIEW_DETAILED_REPORT') {
            this.setState({
                showViewDetailedReport: true,
                viewDetailedReportMeta: {
                    type: Constants.REFRESHER_COURSE,
                    activityId: this.state.diagnosticAssessmentRes.assessmentActivityId,
                    title: "Diagnostic Assessment for Bridge Course",
                    from: Constants.REFRESHER_COURSE,
                    categoryThemeId: '1'
                }
            })

        } else {
            this.props.history.replace({ pathname: Path.REFRESHER_COURSE })
        }



        if (!this.state.diagnosticAssessmentRes)
            return

        // if (!this.userInfo.schoolName || !this.userInfo.schoolLocality) {
        //     this.props.navigation.navigate('AddYourSchoolV2', { meta: { data: this.state.diagnosticAssessmentRes } })
        //     return
        // }

        if (this.state.diagnosticAssessmentRes.status == 'START') {

            // this.props.navigation.navigate('StartAssessment', { meta: { chapterKey: "", chapterTitle: `Diagnostic Assessment for Refresher Course`, categoryThemeId: "1", from: Constants.REFRESHER_COURSE } })
            return
        }

        // if (this.state.diagnosticAssessmentRes.status == 'IN_PROGRESS') {
        //     this.props.navigation.navigate('AssessmentEngine', {
        //         meta: {
        //             chapterTitle: "Diagnostic Assessment for Refresher Course",
        //             isResume: true,
        //             assessmentActivityId: this.state.diagnosticAssessmentRes.assessmentActivityId,
        //             categoryThemeId: "1",
        //             assessmentId: this.state.diagnosticAssessmentRes.assessmentId,
        //             from: constants.REFRESHER_COURSE
        //         }
        //     })
        //     return
        // }

        // if (this.state.diagnosticAssessmentRes.status == 'COMPLETED') {
        //     this.props.navigation.navigate('RefresherCourse', { meta: { from: "REFRESHER_COURSE" } })
        //     return
        // }
    }

    hideViewAnswersScreen() {
        this.setState({ showViewAnswersScreen: false })
    }

    hideViewDetailedReport() {
        this.setState({ showViewDetailedReport: false })
    }

    viewChapterList() {
        this.props.history.push(Path.BROWSE_CHAPTER)
    }

    goToPractice() {
        this.props.history.push(Path.CLASS_SPECIFIC_PRACTICE)
    }

    goToMockTest() {
        this.props.history.push(Path.CLASS_SPECIFIC_ASSESSMENT)
    }

    goToExamPrepKit() {
        this.props.history.push(Path.EXAM_PREP_KIT)
    }

    getClassMockTestStatus(type) {

        // this.setState({ showAssessmentStartScreen: true, type: 'CLASS_MOCK_TEST' })
        this.props.history.push({ pathname: Path.CLASS_DIAGNOSTIC_ASSESSMENT, state: { from: type } })

    }

    onSelectSubject(subject, index) {

        let payload = {
            subjectId: subject._id,
            subjectName: subject.title
        }



        CommonApi.updateProfileSubjectInfo(payload).then(res => {
            console.log(res)
            let userInfo = this.state.userInfo

            this.setState({ subjectId: subject._id, selectedIndex: index, showSubjectSelection: false })

            userInfo.subjectId = subject._id
            userInfo.subjectName = subject.title

            SharedProfile.setProfile(userInfo)


        }).catch(err => {
            console.log(err)
        })
    }

    showSubscriptionPopup() {
        this.setState({ showSubscriptionPopup: true })
    }

    onStart = (data) => {
        console.log(data)
        if (!this.state.userInfo.isSubscribed && data.selectedChapterIndex > 1) {
            this.setState({
                showSubscriptionPopup: true,
                chapterKey: data.chapterKey,
                chapterTitle: data.chapterTitle,
                categoryThemeId: data.categoryId,
                isLocked: data.isLocked,
                imageUrl: data.imageUrl
                // from: data.from ? data.from : '',
                // questionSetId: data.questionSetId ? data.questionSetId : null,
            })
            return;
        }

        if (this.state.userInfo.isSubscribed && data.isLocked) {
            this.setState({
                showLockModal: true,
                chapterKey: data.chapterKey,
                chapterTitle: data.chapterTitle,
                categoryThemeId: data.categoryId,
                isLocked: data.isLocked,
                imageUrl: data.imageUrl
                // from: data.from ? data.from : '',
                // questionSetId: data.questionSetId ? data.questionSetId : null,
            })
            return;
        }

        if ((!this.state.userInfo.isSubscribed && data.selectedChapterIndex <= 1) ||
            (!data.isLocked && this.state.userInfo.isSubscribed)) {
            this.setState({
                showChapterActivityList: true,
                chapterMeta: {
                    chapterKey: data.chapterKey,
                    chapterTitle: data.chapterTitle,
                    categoryThemeId: data.categoryId,
                    isLocked: data.isLocked,
                    imageUrl: data.imageUrl
                }
                // from: data.from ? data.from : '',
                // questionSetId: data.questionSetId ? data.questionSetId : null,
            })
            return;
        }

    }

    closeModal = () => {
        this.setState({
            showLockModal: false
        })
    }

    closeSubscriptionPopup() {
        this.setState({
            showSubscriptionPopup: false
        })
    }

    hideChapterActivityList() {
        this.setState({ showChapterActivityList: false })
    }



    render() {

        // console.log(this.state.subjectsList, this.state.userInfo.class)

        if (this.state.showViewAnswersScreen) {
            return (
                <MyAssessmentResult meta={this.state.assessmentEndScreenInfo}
                    hideViewAnswersScreen={() => this.hideViewAnswersScreen()}
                    delegate={this} />
            )
        }

        if (this.state.showViewDetailedReport) {
            return <DiagnosticAssessmentReport
                delegate={this.props.delegate}
                meta={this.state.viewDetailedReportMeta}
                hideViewDetailedReport={() => this.hideViewDetailedReport()} />
        }

        // if (this.state.showAssessmentStartScreen) {
        //     return <StartAssessment meta={{ from: this.state.type }} />
        // }

        if (this.state.showChapterActivityList) {
            return <ChapterActivityList delegate={this} meta={{...this.state.chapterMeta}} />
        }





        return (
            <div className="home-component container-fluid">
                <div>
                    <img className="eclipse-image" src={getImageSource(ImageConstants.HOME_ECLIPSE)} />
                </div>
                <div className="row align-items-center mt-4">
                    <div className="col">
                        <div className="student-name">Hello {this.state.userInfo.name} !</div>
                        <div className="welcome-text">Welcome to Countingwell</div>
                    </div>
                    {
                        !this.state.userInfo.isSubscribed && !this.state.showBlur ?
                            <div className="col d-flex justify-content-center col-max-width upgrade-to-premium cursor-pointer" onClick={() => this.showSubscriptionPopup()}>
                                <div className=""><img className="badge-icon mr-3" src={getImageSource(ImageConstants.BADGE_IMAGE)} />Upgrade to Premium</div>
                            </div>
                            :
                            <></>
                    }

                    <div className="col"></div>
                </div>
                {
                    Constants.CLASS_SPECIFIC_CONFIGURATION.includes(this.state.userInfo.class) ?
                        <>
                            {
                                this.state.showSubjectSelection ?
                                    <div className="select-subject-screen">
                                        <div className="text-center select-subject-text pt-5">Select a Subject</div>
                                        <div className="mt-5">
                                            {
                                                this.state.subjectsList && this.state.subjectsList.length ?
                                                    <div className="d-flex justify-content-center">
                                                        {this.state.subjectsList.map((subject, index) => (
                                                            <div className="mr-5 mb-5 d-flex justify-content-center align-items-center subject-card cursor-pointer"
                                                                onClick={() => this.onSelectSubject(subject, index)}>
                                                                <div className="radio-button-wrapper mr-3 d-flex align-items-center justify-content-center">
                                                                    {this.state.subjectId === subject._id ? <div className="radio-selected"></div> : <></>}
                                                                </div>
                                                                <div className="mr-3"><img className="image-size" src={getImageSource(subject.title === 'MATHS' ? ImageConstants.MATHS_ICON : subject.title === 'SCIENCE' ? ImageConstants.CHEMISTRY_ICON : ImageConstants.MATHS_ICON)} /></div>
                                                                <div className="subject-text">{subject.title}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    :
                                                    <div className="text-center mt-3">No Subjects Found</div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }

                            {
                                this.state.userInfo.subjectId && !this.state.showSubjectSelection ?
                                    <div className="class-specific-my-to-do mt-5 mb-5">
                                        <div className="to-do-text">Select a chapter to start your learning</div>
                                        <CommonChapterList
                                            delegate={this}
                                            type={'HOME_COMPONENT'}
                                            onSelectChapter={(chapter) => this.selectedChapter(chapter)} />
                                    </div>
                                    :
                                    <></>
                            }
                        </>
                        :
                        <>
                            {/* <div className="pr-3">
                                {
                                    this.state.userInfo.isSubscribed ?
                                        <div className="header-image-container cursor-pointer mt-5" onClick={() => this.onPressDiagnosticAssessment()}>
                                            <img className="p-4 header-banner"
                                                src='https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/504e48e7-d9fe-43b3-8d5e-34e2d3bc09a7_7.png' />
                                        </div>
                                        :
                                        <div className="header-image-container mt-5" >
                                            <img className="p-4 header-banner cursor-pointer"
                                                onClick={() => this.onPressDiagnosticAssessment()}
                                                src='https://countingwell-public-content-prod.s3.ap-south-1.amazonaws.com/504e48e7-d9fe-43b3-8d5e-34e2d3bc09a7_7.png' />
                                        </div>
                                }

                            </div> */}

                            {/* <div className="pr-3">
                    {
                        this.state.userInfo.isSubscribed ?
                            <div className="header-image-container cursor-pointer mt-5" onClick={() => this.navigateToLaunchedContent()}>
                                <img className="p-4 header-banner"
                                    src={this.state.dynamicContent? this.state.dynamicContent.backgroundImageUrl : <></>} />
                            </div>
                            :
                            <div className="header-image-container mt-5" >
                                <img className="p-4 header-banner cursor-pointer"
                                    onClick={() => this.navigateToLaunchedContent()}
                                    src={this.state.dynamicContent? this.state.dynamicContent.backgroundImageUrl : <></>} />
                            </div>
                    }

                </div> */}

                            {/* {
                                this.state.userInfo.isSubscribed ?
                                    <div className="header-image-container mt-5 cursor-pointer" onClick={() => this.goToEventList()} >
                                        <img className="p-4 header-banner"
                                            src={getImageSource(ImageConstants.PREMIUM_USER_BANNER_START_ASSESSMENT)} />
                                    </div>
                                    :
                                    <div className="header-image-container mt-5" >
                                        <img className="p-4 header-banner cursor-pointer"
                                            onClick={() => this.openSubscriptionPage()}
                                            src={getImageSource(ImageConstants.BASIC_USER_BANNER_TWO)} />
                                    </div>
                            } */}


                            <div className="my-to-do mt-4">
                                <div className="to-do-text">My To Do
                                    {/* <div className="cursor-pointer" onClick={() => this.props.history.replace(Path.LEARNING_CONTENT)}>
                            Start Learning Experience</div> */}
                                </div>
                                {/* <div className="sub-heading mt-1">Sub text here</div> */}
                                <div className="homeworks-card mt-3 row ml-0 mr-0">
                                    {/* <div className="col card-max-width homework position-relative cursor-pointer" onClick={() => this.goToHomeworkList()}>
                            {
                                this.state.activeHomeworkCount ?
                                    <div className="homework-count-wrapper">
                                        <div className="homework-count text-center">{this.state.activeHomeworkCount}</div>
                                    </div> : <></>
                            }
                            {
                                this.state.passedDueDateHomeworkCount ?
                                    <div className="homework-passed-count-wrapper">
                                        <div className="homework-count text-center">{this.state.passedDueDateHomeworkCount}</div>
                                    </div>
                                    :
                                    <></>
                            }
                            <img className="right-arrow" src={getImageSource(ImageConstants.RIGHT_DARK_ARROW)} />
                            <img className="ellipse-icon" src={getImageSource(ImageConstants.ELLIPSE_ICON)} />
                            <div className="p-3">
                                <img className="homework-book-icon mr-2" src={getImageSource(ImageConstants.HOMEWORK_BOOK_ICON)}></img>
                                <span className="my-homework-text">My Homeworks</span>
                            </div>
                            <div className="pl-5">
                                {
                                    this.state.activeHomeworkCount ?
                                        <div>
                                            <img className="active-homework-icon mr-2" src={getImageSource(ImageConstants.ACTIVE_HOMEWORK_ICON)} />
                                            <span className="new-homework-text">{this.state.activeHomeworkCount} New Homework received</span>
                                        </div>
                                        :
                                        <></>
                                }
                                {
                                    this.state.passedDueDateHomeworkCount ?
                                        <div className="mt-2">
                                            <img className="active-homework-icon mr-2" src={getImageSource(ImageConstants.RED_RIPPLE_ICON)} />
                                            <span className="pending-homework-text">{this.state.passedDueDateHomeworkCount} Homework pending</span>
                                        </div>
                                        :
                                        <></>
                                }

                            </div>
                        </div> */}
                                    <div className="col card-max-width classwork position-relative cursor-pointer" onClick={() => this.goToClassworkList()}>
                                        {
                                            this.state.activeClassworkCount ?
                                                <div className="homework-count-wrapper">
                                                    <div className="homework-count text-center">{this.state.activeClassworkCount}</div>
                                                </div> : <></>
                                        }
                                        {
                                            this.state.passedDueDateClassworkCount ?
                                                <div className="homework-passed-count-wrapper">
                                                    <div className="homework-count text-center">{this.state.passedDueDateClassworkCount}</div>
                                                </div>
                                                :
                                                <></>
                                        }
                                        <img className="right-arrow cursor-pointer" src={getImageSource(ImageConstants.BLUE_ARROW_ICON)} />
                                        <img className="polygon-icon" src={getImageSource(ImageConstants.POLYCON_ICON)} />
                                        <div className="p-3 d-flex align-items-center">
                                            <img className="homework-book-icon mr-2" src={getImageSource(ImageConstants.BLUE_EXAM_PAD_ICON)}></img>
                                            <span className="my-homework-text">School Assignments</span>
                                        </div>
                                        <div className="pl-5">
                                            {
                                                this.state.activeClassworkCount ?
                                                    <div className="d-flex align-items-center new-assignment-wrapper">
                                                        <img className="active-homework-icon mr-2" src={getImageSource(ImageConstants.WHITE_STAR_ICON)} />
                                                        <span className="new-homework-text">{this.state.activeClassworkCount} New School Assignment Received</span>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                            {
                                                this.state.passedDueDateClassworkCount ?
                                                    <div className="mt-3 d-flex align-items-center panding-assignment-wrapper">
                                                        <img className="active-homework-icon mr-2" src={getImageSource(ImageConstants.RED_RIPPLE_ICON)} />
                                                        <span className="pending-homework-text">{this.state.passedDueDateClassworkCount} School Assignment Overdue</span>
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                        </div>
                                    </div>
                                </div>

                                {/* <div className="events-card mt-5 row ml-0 mr-0"> */}
                                <div className="col card-max-width event-bg position-relative cursor-pointer pl-0 pr-0 mt-5" onClick={() => this.goToEventList()}>
                                    <div className="col card-max-width event-bg position-relative cursor-pointer pl-0 pr-0" >
                                        <img className="right-arrow cursor-pointer" src={getImageSource(ImageConstants.BLUE_ARROW_ICON)} />
                                        <img className="ellipse-icon" src={getImageSource(ImageConstants.EVENTS_POLYCON_ICON)} />
                                        <div className="p-3 d-flex align-items-center">
                                            <img className="homework-book-icon mr-2" src={getImageSource(ImageConstants.CUP_ICON)}></img>
                                            <span className="my-homework-text">Events</span>
                                        </div>
                                        <div className="pl-5">

                                            {
                                                this.state.events.length ?
                                                    <>
                                                        <div className="d-flex align-items-center new-assignment-wrapper">
                                                            <img className="active-homework-icon mr-2" src={getImageSource(ImageConstants.WHITE_STAR_ICON)} />
                                                            {/* <span className="new-homework-text text-color">You have registered for {`${this.state.events.length}`} Events</span> */}
                                                            <span className="new-homework-text text-color">Click here to View the MSF - Recognising Ramanujan - 2024 Result</span>

                                                        </div>
                                                    </>

                                                    : <></>
                                            }

                                        </div>
                                    </div>

                                </div>

                                {
                                    this.state.showRefresherCourseCard ?
                                        <div className="homeworks-card mt-5 row ml-0 mr-0">
                                            <div className="col card-max-width course-bg position-relative pl-0 pr-0">
                                                {/* <img className="right-arrow cursor-pointer" src={getImageSource(ImageConstants.BLUE_ARROW_ICON)} /> */}
                                                <img className="ellipse-icon" src={getImageSource(ImageConstants.COURSES_ELLIPSE_ICON)} />
                                                <div className="p-3">
                                                    <img className="homework-book-icon mr-2" src={getImageSource(ImageConstants.PAPER_ICON)}></img>
                                                    <span className="my-homework-text">Bridge Course ( Starts on 22 April 2024 )</span>
                                                </div>
                                                <div className="pl-5">
                                                    <div className="d-flex align-items-center new-assignment-wrapper">
                                                        <img className="active-homework-icon mr-2" src={getImageSource(ImageConstants.WHITE_STAR_ICON)} />
                                                        <span className="new-homework-text text-color">Diagnostic Assessment and Bridge Course</span>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    {
                                                        this.state.diagnosticAssessmentRes.status === 'START' ?
                                                            <div className="button-wrapper d-flex justify-content-center">
                                                                <div className="d-flex justify-content-center" >
                                                                    <div className="know-more-button cursor-pointer" onClick={() => this.onPressDiagnosticAssessment()}>
                                                                        {'START'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        this.state.diagnosticAssessmentRes.status === 'IN_PROGRESS' ?
                                                            <div className="button-wrapper d-flex justify-content-center">
                                                                <div className="d-flex justify-content-center" >
                                                                    <div className="know-more-button cursor-pointer" onClick={() => this.onPressDiagnosticAssessment()}>
                                                                        {'RESUME'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        this.state.diagnosticAssessmentRes.status === 'COMPLETED' ?
                                                            <div className="button-wrapper d-flex justify-content-center">
                                                                <div className="d-flex justify-content-center mr-3" >
                                                                    <div className="view-answers-button cursor-pointer" onClick={() => this.onPressDiagnosticAssessment('VIEW_ANSWERS')}>
                                                                        {'VIEW ANSWERS'}
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-center mr-3" >
                                                                    <div className="detailed-report-button cursor-pointer" onClick={() => this.onPressDiagnosticAssessment('VIEW_DETAILED_REPORT')}>
                                                                        {'VIEW DETAILED REPORT'}
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-center" >
                                                                    <div className="know-more-button cursor-pointer" onClick={() => this.onPressDiagnosticAssessment()}>
                                                                        {'START BRIDGE COURSE'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>



                            <div className="start-learning mt-4 mb-5">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <div className="to-do-text">Start Learning</div>
                                        <div className="sub-heading mt-1">Select a chapter to start your learning</div>
                                    </div>
                                    {/* <div className="mr-1">
                                        <div className="text-center view-all-button cursor-pointer" onClick={() => this.viewChapterList()}>
                                            View All
                                        </div>
                                    </div> */}
                                </div>
                                <div className="mt-3 d-flex align-items-center">
                                    <ChapterList delegate={this} />
                                </div>
                            </div>
                            {/* <div className="mt-4">
                    <img className="fun-zone-image" src={getImageSource(ImageConstants.FUN_ZONE_IMAGE)} />
                </div> */}
                            {/* <div className="">
                    <div className="mt-4">
                        <img className="home-screen-footer" src={getImageSource(ImageConstants.HOME_SCREEN_FOOTER)} />
                    </div>
                </div> */}
                        </>
                }

                {
                    this.state.showLockModal && <ChapterLockModal delegate={this} modal={this.state.showLockModal} chapterTitle={this.state.chapterTitle} imageUrl={this.state.imageUrl} />
                }

                {
                    this.state.showSubscriptionPopup && <CwSubscriptionModal showSubscriptionPopup={this.state.showSubscriptionPopup} delegate={this} modal={this.state.showSubscriptionPopup} chapterTitle={this.state.chapterTitle} imageUrl={this.state.imageUrl} />
                }


            </div>
        )
    }
}

export default HomeComponent;